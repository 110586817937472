(function () {
	'use strict';
	angular
		.module('app', ['core', 'ngRoute'])
		.constant('environment', {
			touchSupported:
				'ontouchstart' in window ||
				navigator.MaxTouchPoints > 0 ||
				navigator.msMaxTouchPoints > 0,
			isExtension: isExtension(),
			isPhone:
				isExtension() ||
				(window.matchMedia(
					'(min-device-width: 240px) and (max-device-width: 760px)'
				).matches &&
					window.screen.width <= 760), // screen.width added to support firefox in salesforce
			isStandAlone: isStandAlone(),
			isIos: isIos(),
			isAndroid: isAndroid(),
			isWindowsPhone: isWindowsPhone(),
			isMobileDevice:
				isIos() || isAndroid() || isWindowsPhone() || isMobileApple(),
			isSafari: isSafari(),
			isIE: isIE(),
			isChrome: isChrome(),
			isSalesforce: isSalesforce(),
			isFilemakerJS: isFilemakerJS(),
			isFilemakerWebDirect: isFilemakerWebDirect(),
			isShare: isShare(),
			urlParams: urlParams(),

			browserSupported: !!window.HTMLCanvasElement, //We check for canvas support to see if we are in a modern browser. IE 9+ Safari 5.1+
		})
		.config(['$routeProvider', '$locationProvider', 'environment', router])

		//Config for production ToDo: Add this to grunt config so we don't have this run in dev
		.config([
			'$compileProvider',
			function ($compileProvider) {
				//Disable debug classes (ng-scope)
				$compileProvider.debugInfoEnabled(false);
			},
		])

		.run(['environment', 'utilities', initialize]);

	function urlParams() {
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = window.location.search.substring(1).replace(/^\//, ''); // Remove first character if it is a forward slash

		var params = {};
		while ((match = search.exec(query))) {
			params[decode(match[1])] = decode(match[2]);
		}
		return params;
	}

	function isExtension() {
		//Search url parameter for extension flag
		var urlParams = window.location.search;
		return urlParams.indexOf('extension=ChromeV1') > -1;
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isMobileApple() {
		var maxTouchPoints = window.navigator.maxTouchPoints;
		// If there are touch points and it is the safari browser
		return maxTouchPoints > 0 && isSafari();
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}

	function isStandAlone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isSafari() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return (
			userAgent.indexOf('safari') !== -1 &&
			userAgent.indexOf('chrome') === -1
		);
	}

	//Detect if browser is chrome
	function isChrome() {
		var isChromium = window.chrome,
			winNav = window.navigator,
			vendorName = winNav.vendor,
			isOpera = winNav.userAgent.indexOf('OPR') > -1,
			isIEedge = winNav.userAgent.indexOf('Edge') > -1,
			isIOSChrome = winNav.userAgent.match('CriOS');
		if (isIOSChrome) {
			return false;
		} else if (
			isChromium !== null &&
			isChromium !== undefined &&
			vendorName === 'Google Inc.' &&
			isOpera == false &&
			isIEedge == false
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * detect IE
	 * returns version of IE or false, if browser is not Internet Explorer
	 */
	function isIE() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	}

	// Detect if we are running in Salesforce
	function isSalesforce() {
		return fbk.isSalesforce();
	}

	function isFilemakerJS() {
		var params = urlParams();
		return params.type === 'FileMakerJS';
	}

	function isFilemakerWebDirect() {
		var params = urlParams();
		return params.isWebDirect;
	}

	function isShare() {
		var location = window.location;

		var isLocation =
			location.pathname === '/shared' ||
			location.pathname.indexOf('/shared/') > -1;
		var isHash =
			location.hash === '#/shared' ||
			location.hash.indexOf('/shared/') > -1;

		return isLocation || isHash;
	}

	function router($routeProvider, $locationProvider, environment) {
		var isPhone = environment.isPhone;

		// Setting hash prefix to nothing will make sure the default of ! isn't displayed after hash
		$locationProvider.hashPrefix('');
		$routeProvider
			// route for the home page
			.when('/', {
				templateUrl: isPhone
					? 'app/mobile/calendar.html'
					: 'app/calendar.html',
				controller: 'CalendarCtrl',
				reloadOnSearch: false,
				resolve: {
					init: function (
						$q,
						$location,
						$timeout,
						calendarInit,
						manageUser
					) {
						var keyPressed;
						document.addEventListener('mousemove', logKey);

						function logKey(e) {
							keyPressed = e.shiftKey;
						}

						var deferred = $q.defer();
						var shareID = manageUser.getShareID();
						//If we are coming from a share (url changed from a share) we need to reload to force updating login and user
						if (shareID) {
							window.location.reload();
							return;
						}
						var auth = manageUser.getAuth(true);
						auth.then(
							function (authData) {
								document.removeEventListener(
									'mousemove',
									logKey
								);

								if (keyPressed) {
									deferred.reject();
									$location.path('/settings');
									return;
								}

								calendarInit.init(
									false,
									false,
									false,
									resolveMe
								);
								function resolveMe() {
									deferred.resolve();
								}
							},
							function (error) {
								document.removeEventListener(
									'mousemove',
									logKey
								);
								deferred.reject();
								$location.path('/sign-up');
							}
						);
						return deferred.promise;
					},
				},
			})
			// route for shared events
			.when('/shared/:id?', {
				templateUrl: isPhone
					? 'app/mobile/calendar.html'
					: 'app/calendar.html',
				controller: 'CalendarCtrl',
				reloadOnSearch: false,
				resolve: {
					init: function (
						$q,
						$location,
						$route,
						$timeout,
						calendarInit,
						manageUser
					) {
						if (!$route.current.params.id) {
							$location.path('/share-expired');
							return;
						}
						var shareID = $route.current.params.id;
						var deferred = $q.defer();
						manageUser.setShareID(shareID);
						var auth = manageUser.getAuth(true);
						auth.then(
							function (authData) {
								calendarInit.init(
									false,
									false,
									true,
									resolveMe
								);
								function resolveMe() {
									deferred.resolve();
								}
							},
							function (error) {
								deferred.reject();
								$location.path('/share-expired');
							}
						);
						return deferred.promise;
					},
				},
			})
			// route for shared events
			.when('/share-expired/:type?', {
				templateUrl: 'app/options/share-expired.html',
				controller: 'UserCtrl',
			})
			// route for the sign-in page
			.when('/sign-in/:type?', {
				templateUrl: 'app/user/sign-in.html',
				controller: 'UserCtrl',
				resolve: {
					init: function ($q) {
						var deferred = $q.defer();
						// If we are preventing auth we assume an outage and redirect sign in / sign up to outage page
						if (_CONFIG.DBK_PREVENT_FIREBASE_AUTH) {
							deferred.reject();
							window.location.href = 'https://dayback.com/outage';
						} else {
							deferred.resolve();
						}
						return deferred.promise;
					},
				},
			})
			// route for the sign-up page
			.when('/sign-up/:type?', {
				templateUrl: 'app/user/sign-up.html',
				controller: 'UserCtrl',
				resolve: {
					init: function ($q) {
						var deferred = $q.defer();
						// If we are preventing auth we assume an outage and redirect sign in / sign up to outage page
						if (_CONFIG.DBK_PREVENT_FIREBASE_AUTH) {
							deferred.reject();
							window.location.href = 'https://dayback.com/outage';
						} else {
							deferred.resolve();
						}
						return deferred.promise;
					},
				},
			})
			// route for the sign-out page
			.when('/sign-out/', {
				templateUrl: 'app/user/sign-out.html',
				controller: 'SignOutCtrl',
				resolve: {
					init: function ($q, manageUser) {
						// Don't allow route to resolve if we are using auto sign-in
						var deferred = $q.defer();
						if (
							manageUser.getLinkedAccount() ||
							manageUser.getLinkedUserToken()
						) {
							deferred.reject();
						} else {
							deferred.resolve();
						}
						return deferred.promise;
					},
				},
			})
			// route for the forgot-password page
			.when('/forgot-password/:type?', {
				templateUrl: 'app/user/forgot-password.html',
				controller: 'UserCtrl',
			})
			// route for the change-password page
			.when('/change-password/:type?', {
				templateUrl: 'app/user/change-password.html',
				controller: 'PasswordCtrl',
				resolve: {
					auth: function ($q, $location, manageUser) {
						var deferred = $q.defer();
						var auth = manageUser.getAuth(true);
						auth.then(
							function (authData) {
								deferred.resolve(authData);
							},
							function (error) {
								deferred.reject();
								$location.path('/sign-in');
							}
						);
						return deferred.promise;
					},
				},
			})
			// route for google auth redirect in salesforce
			.when('/oauth-close', {
				templateUrl: 'app/user/oauth-close.html',
				resolve: {
					init: function ($q, $location, $timeout) {
						$timeout(function () {
							window.close();
						}, 1000);
					},
				},
			})
			// route for the settings page
			.when('/settings/:category?/:type?/:item?/:section?', {
				templateUrl: isPhone
					? 'app/mobile/settings/settings.html'
					: 'app/settings/settings.html',
				controller: 'ConfigCtrl',
				reloadOnSearch: false,
				resolve: {
					init: function (
						$q,
						$location,
						$timeout,
						calendarInit,
						manageUser
					) {
						var deferred = $q.defer();

						var auth = manageUser.getAuth(true);
						auth.then(
							function (authData) {
								//Load inline code editing for settings
								loadStylesheet(
									`${_CONFIG.DBK_BASEURL}libraries/codemirror/dist/codemirror-dist.css`
								);
								loadScript(
									`${_CONFIG.DBK_BASEURL}libraries/codemirror/dist/codemirror-dist.js`
								);

								calendarInit.init(
									true,
									false,
									false,
									resolveMe
								);
								function resolveMe() {
									deferred.resolve();
								}
							},
							function (error) {
								deferred.reject();
								$location.path('/sign-up');
							}
						);

						return deferred.promise;
					},
				},
			})
			// route for the activation page
			.when('/activate/:userID?/:subscriptionID?/', {
				templateUrl:
					'app/activation/subscriptions/activate-subscription.html',
				controller: 'SubscriptionsCtrl',
				reloadOnSearch: false,
			})
			// route for the sign-up page
			.when('/seedcode-dev-admin', {
				resolve: {
					init: function ($q, $location, seedcodeDev) {
						const deferred = $q.defer();

						seedcodeDev.init(resolveMe);

						function resolveMe(authorized) {
							if (authorized) {
								deferred.resolve();
							} else {
								deferred.reject();
								$location.path('/');
							}
						}
						return deferred.promise;
					},
				},
				templateUrl: 'app/seedcode/seedcode.html',
				controller: 'SeedcodeCtrl',
			})
			.otherwise({
				resolve: {
					init: function ($q, $location) {
						var state, path, params, paramItem;
						var deferred = $q.defer();
						var currentURL = $location.url();
						var urlExplode = currentURL.split('&');

						//Clear the current location which may contain encoded state data
						$location.url(null);

						//Check and retrieve any state parameters used to store params
						for (var i = 0; i < urlExplode.length; i++) {
							if (urlExplode[i].indexOf('state=') > -1) {
								state = urlExplode[i].split('=')[1];
							}
						}

						//If there is a state parameter then parse the path and params from that
						if (state) {
							state = decodeURIComponent(state).split('?');
							path = state[0].replace('#', '');
							if (state.length > 1) {
								params = state[1].split('&');
								//Assign any parameters that may exist
								for (var i = 0; i < params.length; i++) {
									paramItem = params[i].split('=');
									$location.search(
										paramItem[0],
										paramItem[1]
									);
								}
							}
							$location.path(path);
						}
						//If there is not state parameter treat this as a mistaken path and add a referrer param with the attempted url
						else {
							$location.path('/');
							$location.search('referrer', currentURL);
						}

						//Replace the url so we can't go back to the improperly formated url
						$location.replace();

						//Resolve and return the promise so the new path loads
						deferred.resolve();
						return deferred.promise;
					},
				},
			});

		//Below function will remove the hash in urls. However unless something is changed on the backened those urls cannot be visited directly (hash still required)
		//Only switch to html5 mode if we aren't using it in a web viewer and we aren't in SF
		if (environment.isShare) {
			$locationProvider.html5Mode({
				enabled: true,
				requireBase: false,
			});
		}
	}

	function initialize(environment, utilities) {
		//Check if we are running in a supported browser
		const browserSupported = environment.browserSupported;
		const notSupportedMessage =
			'<h1>Sorry but you need a newer browser</h1><p>Check out our <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.SystemRequirements" target="_blank">minimum requirements</a> to see why</p>';
		if (!browserSupported) {
			window.location.href =
				utilities.dataURLMessage(notSupportedMessage);
		}

		const params = urlParams();
		if (params.loaderColor) {
			loadStyle(
				`.horizontal-progress:before {background-color: #${params.loaderColor}}`
			);
		}

		// Set environment vars to global window for reference in other libraries outside of angular scope. Namespace to dbk
		window.dbk = environment;

		// Request Animation Frame Shim - Used for animations in certain scenarios
		// http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
		window.requestAnimFrame = (function () {
			return (
				window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				function (callback) {
					window.setTimeout(callback, 1000 / 60);
				}
			);
		})();

		// Check if we are running in salesforce
		if (environment.isSalesforce && !environment.isPhone) {
			//Load salesforce css styles
			loadStylesheet(
				`${_CONFIG.DBK_BASEURL}css/platforms/salesforce.css`
			);
		} else if (environment.isSalesforce) {
			//Load salesforce mobile css styles
			loadStylesheet(
				`${_CONFIG.DBK_BASEURL}css/platforms/salesforce-mobile.css`
			);
		}

		loadMetaPreview('og:description', 'dynamic page description');

		// Load large libraries after calendar loaded
		window.setTimeout(function () {
			loadStylesheet(
				`${_CONFIG.DBK_BASEURL}post-load-javascript/suneditor/suneditor.min.css`
			);
			loadScript(
				`${_CONFIG.DBK_BASEURL}post-load-javascript/suneditor/suneditor.min.js`
			);
		}, 0);

		// initialze service worker
		(function () {
			if (
				window.location.host.includes('.me') ||
				window.location.pathname.includes('/beta/')
			) {
				return;
			}
			window.setTimeout(() => {
				// Register service worker
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker
						.register('service-worker.js')
						.then((registration) => {
							console.log(
								'Service worker registered:',
								registration
							);
							if (registration.installing) {
								console.log('SW installing');
							} else if (registration.waiting) {
								console.log('SW waiting');
							} else if (registration.active) {
								console.log('SW activated');
							}
						})
						.catch((error) => {
							console.error(
								'Service worker registration failed:',
								error
							);
						});
				}
			}, 1000);
		})();
	}

	function loadMetaPreview(property, content, headElement) {
		// var name = removeSpecial(file);
		var fileExists = document.querySelector(
			'meta[property="' + property + '"]'
		);

		if (fileExists) {
			return;
		}

		var head = headElement
			? headElement
			: document.getElementsByTagName('head')[0];
		var meta = document.createElement('meta');
		// meta.property  = property;

		meta.setAttribute('property', property);
		meta.setAttribute('content', content);

		head.appendChild(meta);
	}

	function loadStyle(content, headElement) {
		var head = headElement
			? headElement
			: document.getElementsByTagName('head')[0];
		var style = document.createElement('style');
		style.textContent = content;
		head.appendChild(style);
	}

	function loadStylesheet(file, headElement) {
		var fileID = removeSpecial(file);
		var fileExists = document.getElementById(fileID);

		if (fileExists) {
			return;
		}
		var head = headElement
			? headElement
			: document.getElementsByTagName('head')[0];
		var link = document.createElement('link');
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = file + '?v={build-data::cacheBust}';
		link.id = fileID;
		link.media = 'all';
		head.appendChild(link);
	}

	function loadScript(file, headElement) {
		var fileID = removeSpecial(file);
		var fileExists = document.getElementById(fileID);

		if (fileExists) {
			return;
		}
		var head = headElement
			? headElement
			: document.getElementsByTagName('head')[0];
		var script = document.createElement('script');
		script.src = file + '?v={build-data::cacheBust}';
		script.id = fileID;
		head.appendChild(script);
	}

	function removeSpecial(text) {
		if (!text) {
			return '';
		}

		var lower = text.toLowerCase();
		var upper = text.toUpperCase();
		var result = '';
		for (var i = 0; i < lower.length; ++i) {
			if (lower[i] !== upper[i] || lower[i] === '-') {
				result += text[i];
			}
		}
		return result;
	}
})();
